<template>
    <page-title
        icon="bi-key"
        title="アカウント設定"
    >
    </page-title>

    <inline-loader v-if="loading"></inline-loader>
    <template v-else>
        <section class="section">
            <form @submit.prevent="save">
                <div class="form-group col-md-6 mb-3">
                    <label>氏名</label>
                    <read-only :text="user.user_name"></read-only>
                </div>

                <template v-if="isGoogleAccount">
                    <div class="form-group col-md-6 mb-3">
                        <label>メールアドレス</label>
                        <read-only :text="user.google_email"></read-only>
                    </div>
                </template>
                <template v-else>
                    <div class="form-group col-md-6 mb-3">
                        <label>ログインID</label>
                        <read-only :text="user.login_key"></read-only>
                    </div>
                    <div class="form-group col-md-6 mb-3">
                        <label>パスワード</label>
                        <form-input-password
                            v-model="user.password"
                            pattern="^(?=.*?[a-z])(?=.*?[A-Z])(?=.*?\d)[a-zA-Z\d]{8,1000}$"
                            required="true"
                        ></form-input-password>
                        <div class="text-secondary">＊大文字を含む英数字8文字以上</div>
                    </div>
                    <div class="col d-flex justify-content-between">
                        <button-exec-update
                            size="btn-lg"
                            text="更新"
                        ></button-exec-update>
                    </div>
                </template>
            </form>
        </section>
    </template>
</template>

<script>
import User from '@/models/entities/user'
import AccountType from '@/models/enums/account-type';
import PageTitle from '@/components/PageTitle.vue';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import ButtonExecUpdate from '@/components/buttons/ButtonExecUpdate';
import FormInputPassword from '@/components/forms/FormInputPassword';
import ReadOnly from '@/components/forms/ReadOnly';

export default {
    name: 'Account',
    components: {
        InlineLoader,
        PageTitle,
        ButtonExecUpdate,
        FormInputPassword,
        ReadOnly,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showMessage'
    ],
    data() {
        return {
            loading: true,
            AccountType: AccountType,

            //ユーザー情報
            user: new User()
        }
    },
    mounted() {
        this.$http.get(`/me`)
        .then(response => {
            this.user = new User(response.data);
        })
        .finally(() => {
            this.loading = false;
        });
    },
    computed: {
        //Googleアカウントユーザーかどうか
        isGoogleAccount() {
            if (this.user.account_type === AccountType.GOOGLE_ACCOUNT) {
                return true;
            }
            return false;
        }
    },
    methods: {
        save() {
            this.startScreenLoading();
            this.$http.put(`/me`, this.user)
            .then(() => {
                this.user.password = '';
                this.showMessage('更新しました');
            })
            .finally(() => {
                this.endScreenLoading();
            });
        },
    }
}
</script>

<style scoped>

</style>
